<template>
    <div>
        <div class="header">
            <van-nav-bar :title="$t('czhi.title')" left-arrow @click-left="$router.go(-1)" />
        </div>
        <div class="maincontent">
            <van-form v-model="form">
                <!-- <van-cell :title="$t('czhi.biz')" center>
                    <template #extra>
                        <van-dropdown-menu active-color="#004ea3">
                            <van-dropdown-item v-model="bizhong" :options="bzoptions" />
                        </van-dropdown-menu>
                    </template>
</van-cell> -->
                <van-cell :title="$t('czhi.lname')" center>
                    <template #extra>
                        <van-dropdown-menu active-color="#004ea3">
                            <van-dropdown-item v-model="productvalue" :options="product" />
                        </van-dropdown-menu>
                    </template>
                </van-cell>
                <van-image class="show-img" id="imageWrapper" v-if="wallet[productvalue]"
                    :src="wallet[productvalue].wallet_image" />
                <van-row class="image-body">
                    <van-button @click.prevent="saveimg()" style="width:fit-content ">
                        {{ $t('czhi.saveimg') }}
                    </van-button>
                </van-row>
                <van-row>
                    <van-cell :title="$t('czhi.address')" center>
                        <van-button type="primary" size="small" round
                            @click="copyToClipboard(wallet[productvalue].wallet_url)"
                            style="color: white; background: rgb(4, 172, 146); border-color: rgb(4, 172, 146);height: 32px;width: 100px;">
                            {{ $t('czhi.copy') }}
                        </van-button>
                    </van-cell>

                    <van-field :label="$t('czhi.number')" :placeholder="$t('czhi.enter')" v-model="form.number"
                        :rules="[{ required: true, message: '' }]" />
                    <!-- <van-field :label="$t('czhi.chargeurl')" :placeholder="$t('czhi.enter')" v-model="form.url"
                        :rules="[{ required: true, message: '' }]" /> -->
                    <van-row class="message">{{ $t('czhi.upload') }}</van-row>
                    <van-row class="uploading-image">
                        <van-uploader :beforeRead="beforeRead" :after-read="afterRead">
                            <img :src="uploadimg ? uploadimg : ''" ref="uploadImg" alt="" v-if="uploadimg"
                                style="max-width:100%;max-height:13rem;text-align:center" />
                        </van-uploader>
                    </van-row>
                </van-row>
                <van-button type="primary" round @click="submit"
                    style="color: white; background: rgb(4, 172, 146); border-color: rgb(4, 172, 146);height: 32px;width: 100px;">
                    {{ $t('common.submit') }}
                </van-button>
            </van-form>
        </div>
    </div>
</template>
<script>
import html2canvas from 'html2canvas';
import Clipboard from 'clipboard';
import { Toast } from 'vant';
export default {
    data() {
        return {
            form: {
                url: '已关闭填写用户充值地址入口',
            },
            productvalue: 0,
            product: [],
            bizhong: 1,
            bzoptions: [{ text: 'USDT', value: 1 }, { text: 'BTC', value: 2 }, { text: 'ETH', value: 3 }],
            wallet: [],
            uploadimg: ''
        };
    },
    created() {
        this.getinfo();
    },
    methods: {
        async getinfo() {
            const { data } = await this.$http.get('/home/user/recharge');
            if (data) {
                if (data.code === 200) {
                    console.log("this.wallet", data.data);
                    this.wallet = data.data;
                    this.product = [];
                    this.wallet.forEach((type, index) => {
                        this.product.push({
                            text: type.wallet_type.toUpperCase(),
                            value: index
                        });
                    });
                }
            }
        },
        // 保存图片
        saveimg() {
            html2canvas(document.getElementById('imageWrapper'), {
                allowTaint: true,
                useCORS: true,
                taintTest: true
            }).then(canvas => {
                const saveUrl = canvas.toDataURL('image/png');
                const aLink = document.createElement('a');
                const blob = this.base64ToBlob(saveUrl);
                const evt = document.createEvent('HTMLEvents');
                evt.initEvent('click', true, true);
                aLink.download = 'qr.jpg';
                aLink.href = URL.createObjectURL(blob);
                aLink.click();
                if (aLink.href) {
                    this.$toast.success(this.$t('common.success'));
                }
            });
        },
        // 这里把图片转base64
        base64ToBlob(code) {
            const parts = code.split(';base64,');
            const contentType = parts[0].split(':')[1];
            const raw = window.atob(parts[1]);
            const rawLength = raw.length;
            const uInt8Array = new Uint8Array(rawLength);
            for (let i = 0; i < rawLength; ++i) {
                uInt8Array[i] = raw.charCodeAt(i);
            }
            return new Blob([uInt8Array], { type: contentType });
        },
        // 复制地址
        copyurl() {
            const clipboard = new Clipboard('.icon-copy');
            clipboard.on('success', e => {
                this.$toast.success(this.$t('common.success'));
            });
        },
        beforeRead(file) {
            if (
                file.type !== 'image/jpeg' &&
                file.type !== 'image/png' &&
                file.type !== 'image/gif'
            ) {
                this.$toast(
                    this.getlang('图片格式不正确', localStorage.getItem('lang'))
                );
                return false;
            }
            return true;
        },
        async afterRead(file) {
            const param = new FormData();
            param.append('file', file.file);
            const { data } = await this.$http.post('/home/user/uploadimg', param);
            if (data) {
                this.uploadimg = data.data.url;
                // console.log(this.uploadimg);
            }
        },
        // 提交
        async submit() {
            if (!this.form.number || !this.form.url) {
                return false;
            }
            if (!this.uploadimg) {
                // this.$toast.fail(this.$t('czhi.upload'));
                Toast($t('czhi.upload'));
                return false;
            }
            // 组装数据
            const postform = {
                product_type: this.wallet[this.productvalue].product_type,
                product_type_id: this.wallet[this.productvalue].product_type_id,
                wallet_info: this.wallet[this.productvalue].wallet_type_id,
                wallet_address: this.form.url,
                recharge_image: this.uploadimg, // 充值图片
                recharge_num: this.form.number, // 充值金额
            };
            console.log("postform", postform);
            const { data } = await this.$http.post('/home/user/recharge', postform);
            if (data) {
                if (data.code === 200) {
                    this.uploadimg = '';
                    this.form = {};
                    this.$toast.success(this.$t('czhi.submitok'));
                } else {
                    this.$toast.fail(this.getlang(data.msg, localStorage.getItem('lang')));
                }
            }
        },

        copyToClipboard(text) {
            // 创建一个隐藏的输入框
            const input = document.createElement('input');
            input.value = text;
            document.body.appendChild(input);
            // 选择输入框中的文本
            input.select();
            input.setSelectionRange(0, 99999); // 对于手机设备
            // 复制文本到剪贴板
            document.execCommand('copy');
            // 移除输入框
            document.body.removeChild(input);
            Toast.success('Success');
        }
    }
};
</script>
<style lang="less" scoped>
.maincontent {
    padding-top: 65px;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    font-size: 0.93333rem;
    background: #f7f7f7;

    .van-cell__title {
        text-align: left;
        font-size: 0.93333rem;
    }

    .van-cell {
        font-size: 0.93333rem;
        background-color: #fff !important;
        height: 3.2rem !important;

        &:after {
            border-bottom: 0.02667rem solid #d0d0d1;
        }
    }

    .van-dropdown-menu /deep/.van-dropdown-menu__bar {
        box-shadow: none;
        height: 1rem;
        background: transparent !important;
    }

    .van-dropdown-menu /deep/ .van-dropdown-menu__title {
        color: #212121 !important;
    }

    /deep/.van-dropdown-item {
        margin-top: 1.1rem;
    }

    .van-dropdown-menu /deep/ .van-cell {
        font-size: 0.98667rem;
        background-color: #fff !important;
        height: 3.46667rem;
    }

    .show-img {
        width: 6.4rem;
        margin-top: 0.8rem;
        height: 6.4rem;
    }

    .image-body {
        .van-button {
            color: #fff;
            background-color: #000;
            margin: 0.53333rem 0 1.33333rem 0;
            border: none;
            border-radius: 0.45333rem;
            height: 1.86667rem;
            width: 8rem;
        }

        h3 {
            color: #000;
            font-size: 1.05rem;
        }

        span {
            display: block;
            margin: 0.53333rem;
        }
    }

    .van-field {
        height: 3.4rem;
        font-size: 0.93333rem;

        /deep/.van-field__label {
            width: 140px !important;
            color: #323232;
        }
    }

    .van-cell__value {
        position: relative;
        overflow: hidden;
        color: #969799;
        text-align: right;
        vertical-align: middle;
        word-wrap: break-word;
    }

    /deep/.van-field__error-message--right,
    /deep/.van-field__control,
    /deep/.van-field__error-message {
        text-align: right;
    }

    .message {
        margin-top: 0.8rem;
        font-size: 1.06667rem;
        color: #000;
    }

    .uploading-image {
        height: 13.33333rem;
        width: 90%;
        margin: 2% 5% 5% 5%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0.02667rem dashed grey;

        .van-uploader {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            /deep/.van-uploader__wrapper {
                width: 100%;
                height: 100%;
                justify-content: center;
            }

            /deep/.van-uploader__upload {
                width: 100%;
                height: 100%;
                margin: 0;
                background-color: #fff;
                color: #fff;
            }
        }
    }

    .submitBtns {
        margin-bottom: 20%;
        margin-top: 10%;
        width: 80%;
        letter-spacing: 0.53333rem;
        height: 4rem;
        font-size: 0.93333rem;
        color: #fff;
        background-color: #004ea3;
        border-radius: 0.53333rem;
        border: none;
        box-shadow: 0 0 1.33333rem #004ea3;
    }
}
</style>
